import Select from 'react-select'

import { Step } from '../../resources/ExegeticalResources'

interface ExegeticalResourceDropdownProps {
    glossaryStep?: Step
    glossaryTerms: Step[]
    setStepId: (glossaryTermId: string) => void
}

const stepToOption = (step: Step) => {
    return {
        label: step.title,
        value: step.id
    }
}

export const ExegeticalGlossaryDropdown = ({
    glossaryStep,
    glossaryTerms,
    setStepId
}: ExegeticalResourceDropdownProps) => {
    const options = glossaryTerms.map(stepToOption)

    return (
        <div>
            <Select
                className="exegetical-glossary-custom-select"
                value={glossaryStep ? stepToOption(glossaryStep) : undefined}
                onChange={(selectedOption) => {
                    setStepId(selectedOption?.value || '')
                }}
                options={options}
                isSearchable
                menuShouldBlockScroll
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? '#337ab7' : state.isFocused ? '#e8e8e8' : ''
                    }),
                    container: (provided) => ({
                        ...provided,
                        width: '180px'
                    })
                }}
                filterOption={({ label }, query) => label.toLowerCase().includes(query.toLowerCase())}
            />
        </div>
    )
}
