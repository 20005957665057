export interface PublishedBible {
    id: string
    name: string
    language: {
        name: string
        direction: 'ltr' | 'rtl'
    }
    abbreviation: string
    hasAudio: boolean
    startBook: number
    endBook: number
    versification: 'English' | 'Original'
    copyright?: string
    audioCopyright?: string
    audioStartBook?: number
    audioEndBook?: number
    isForFOBAIOnly?: boolean
}

export enum DbObjectIdPrefix {
    PASSAGE_DOCUMENT = 'pasDoc_',
    PROJECT_DOCUMENT = 'prjDoc_'
}

export enum ExportSourceType {
    PASSAGE_VIDEO = 'passageVideo',
    PORTION = 'portion',
    PROJECT = 'project'
}

export enum ExportType {
    RECORDING,
    BACK_TRANSLATION,
    TRANSCRIPTION
}

export enum ExportTextFormat {
    USFM = 'USFM',
    TEXT = 'Text'
}

export enum ExportTextType {
    TRANSCRIPTION = 'transcription',
    BACK_TRANSLATION = 'backTranslation'
}

export enum FileExtension {
    ZIP = '.zip',
    JSON = '.json'
}

export enum MimeType {
    JSON = 'application/json',
    ZIP = 'application/zip',
    WAV = 'audio/wav',
    OPUS = 'audio/ogg',
    MP3 = 'audio/mpeg',
    MP4 = 'video/mp4'
}

export enum MediaType {
    AUDIO = 'Audio',
    TEXT = 'Text',
    IMAGES = 'Images'
}

export enum CacheType {
    BIBLES = 'publishedBibles',
    EXEGETICAL_RESOURCES = 'exegeticalResources', // FIA Study guides
    IMAGES = 'images' // MARBLE images
}

export type CacheRequest = {
    cacheType: CacheType
    language: string
    mediaType?: MediaType
    bibleVersion?: PublishedBible
}

export enum CachingProgress {
    REQUESTED,
    CACHED,
    IN_PROGRESS,
    UPDATE_AVAILABLE,
    ERROR
}

export enum ExportProgress {
    NOT_STARTED,
    IN_PROGRESS,
    ERROR,
    FINISHED
}

export type CacheIndexEntry = {
    key: string
    generatedAt: number
}

export enum CacheSource {
    CacheStorage = 'cacheStorage',
    CacheIndex = 'translationResources'
}

export enum RecordingType {
    BASE,
    APPENDED_SEGMENT,
    PATCH
}

export type PassageDocumentCopyType = 'passageRecording' | 'backTranslation'

export enum RecordingMediaType {
    AUDIO = 'Audio',
    VIDEO = 'Video'
}

export type NoteMarker = {
    component: JSX.Element
    shape: 'circle' | 'square' | 'triangle'
}

export type SegmentDocumentType = 'transcription' | 'backTranslationText'

type UploadError = {
    type: 'error'
    error: any
}

type UploadSuccess = {
    type: 'success'
    url: string
    duration: number
    mimeType: string
}

export type RecordingDoneParams = UploadError | UploadSuccess

type BlobVerifyError = {
    type: 'error'
    error: string
}

type BlobVerifySuccess = {
    type: 'success'
    blob: Blob
}

export type BlobVerifyResult = BlobVerifyError | BlobVerifySuccess

export type TextHistoryEntry = {
    text: string
    modBy: string
    modDate: string
}

export enum DateFormat {
    mmddyyyy = 'MM/dd/yyyy',
    ddmmyyyy = 'dd/MM/yyyy',
    mmmmddyyyy = 'MMMM dd, yyyy',
    ddmmmmyyyy = 'dd MMMM yyyy',
    yyyymmdd = 'yyyy-MM-dd'
}

export enum AVTTError {
    COULD_NOT_COPY = 'COULD_NOT_COPY',
    LIMITS_MAX_USERS_PER_PROJECT = 'LIMITS_MAX_USERS_PER_PROJECT',
    LIMITS_MAX_PORTIONS_PER_PROJECT = 'LIMITS_MAX_PORTIONS_PER_PROJECT',
    LIMITS_MAX_PASSAGES_PER_PORTION = 'LIMITS_MAX_PASSAGES_PER_PORTION'
}
