/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */

import { FC, useState, useEffect, useRef, useCallback } from 'react'

import { observer } from 'mobx-react'
import { Dropdown, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { BibleTranslationTitle } from './BibleTranslationTitle'
import ERTermModal from './ERTermModal'
import { useFetchBlob } from '../../hooks/useFetchBlob'
import { PassageHighlight } from '../../models3/PassageHighlight'
import { ProjectTerm } from '../../models3/ProjectTerm'
import { Root } from '../../models3/Root'
import { ChapterTimeCodes } from '../../resources/AudioResource'
import { EnhancedResources, ERDiv, ERSpan } from '../../resources/EnhancedResources'
import { ImageResolution } from '../../resources/MARBLEImages'
import { audioBookExists } from '../../resources/publishedBibles'
import { RefRange, refToBookId, refToChapterId, refToVerseId } from '../../resources/RefRange'
import { PublishedBible } from '../../types'
import { usePublishedBibles } from '../app/PublishedBiblesContext'
import { MARBLE_IMAGES_BASE_PATH } from '../app/slttAvtt'
import { BibleAudioPlayer } from '../audio/BibleAudioPlayer'
import { PublishedBibleSettings, usePublishedBibleSettings } from '../publishedBibleSettings'
import { ReferenceSearchInput } from '../referenceInput/ReferenceSearchInput'
import { RightPaneId } from '../translation/TranslationEditor'
import { SettingsButton, StarButton } from '../utils/Buttons'
import { groupBy, inRangeInclusive, newlinesToHtmlBreaks } from '../utils/Helpers'

import './EnhancedResources.css'

/*
    EnhancedResourceViewer
        ERTermModal - show info for user selected term
            ERTermView - show info from Lexicon for term
 */

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:EnhancedResourceViewer')

const getERViewerBodyId = (idSuffix: string) => `er-viewer-body-${idSuffix}`
const getERDivsId = (idSuffix: string) => `er-divs-${idSuffix}`

interface IERResourceSelector {
    setBible: (value: PublishedBible) => void
    visibleBibles: PublishedBible[]
    currentBible?: PublishedBible
    idSuffix: string
    pullRight?: boolean
    enabled?: boolean
}

export const ERResourceSelector: FC<IERResourceSelector> = ({
    setBible,
    visibleBibles,
    currentBible,
    idSuffix,
    pullRight,
    enabled
}) => {
    const { t } = useTranslation()
    const marbleResourcesByLanguage = groupBy(visibleBibles, (item) => item.language.name)
    const items = Object.keys(marbleResourcesByLanguage).flatMap((language) => [
        language,
        ...marbleResourcesByLanguage[language]
    ])
    const align = pullRight ? 'end' : undefined

    return (
        <Dropdown id={`enhanced-resource-Bible-dropdown-${idSuffix}`} className="er-resources-select" align={align}>
            <Dropdown.Toggle className="sl-dropdown styled-dropdown-select">
                {currentBible?.abbreviation ?? t('Choose one')}
            </Dropdown.Toggle>
            <Dropdown.Menu className="scrollable-dropdown styled-dropdown-select-menu">
                {items.map((item) => {
                    if (typeof item === 'string') {
                        return <Dropdown.Header key={item}>{item}</Dropdown.Header>
                    }
                    return (
                        <Dropdown.Item
                            onClick={() => setBible(item)}
                            active={item.id === currentBible?.id}
                            key={item.id}
                            disabled={enabled === false}
                        >
                            <div className="er-dropdown-item">
                                <BibleTranslationTitle bible={item} />
                            </div>
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

const ERImage = ({ imagePath }: { imagePath: string }) => {
    const { blobUrl, isError, isLoading } = useFetchBlob(imagePath)

    if (isError || isLoading || !blobUrl) {
        return null
    }

    return <img className="er-image-display" src={blobUrl} />
}

interface IERImageModal {
    imagePaths: string[]
    setImageModalOpen: (open: boolean) => void
}

const ERImageModal: FC<IERImageModal> = ({ imagePaths, setImageModalOpen }) => {
    const basePath = `${MARBLE_IMAGES_BASE_PATH}/images_resolutions/${ImageResolution.MEDIUM}`

    return (
        <Modal style={{ top: '1%' }} size="lg" show backdrop="static" onHide={() => setImageModalOpen(false)}>
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Body>
                <div className="er-images-div">
                    {imagePaths.map((ip, key) => (
                        <ERImage key={key} imagePath={`${basePath}/${ip}`} />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
}
interface IERImageView {
    span: ERSpan
}

const ERImageView: FC<IERImageView> = ({ span }) => {
    const [imageModalOpen, setImageModalOpen] = useState(false)

    const imagePaths = span.attributes.image_links.split('|')

    return (
        <span>
            {imageModalOpen && <ERImageModal {...{ imagePaths, setImageModalOpen }} />}
            <span className="er-image far fa-image" onClick={() => setImageModalOpen(true)} />
        </span>
    )
}
interface IERSpanView {
    rt: Root
    span: ERSpan
    setTermId: (id: string) => void
    highlightedVerse: RefRange[]
    handleClickedVerse: (refs: RefRange[]) => void
}

type ERPlainTextProps = {
    text: string
}

const ERPlainText = ({ text }: ERPlainTextProps) => {
    return <span className="er-text">{text}</span>
}

type ERLinkProps = {
    text: string
    termId: string
    spanType: string
    getProjectTerm: (lexicalLink: string) => ProjectTerm | undefined
    setTermId: (id: string) => void
}

const ERLink = ({ text, termId, spanType, getProjectTerm, setTermId }: ERLinkProps) => {
    const isSigned = !!(getProjectTerm(termId)?.renderings.length ?? 0)

    let className = `er-${spanType}`
    if (isSigned) {
        className += ' er-sign-video-present'
    }

    return (
        <span
            className={className}
            onClick={() => {
                // termId = "SDBG:γένεσις:000001|SDBG:γένεσις:000002"
                log('ERSpanView click', JSON.stringify(termId.split(':')))
                setTermId(termId)
            }}
        >
            {text}
        </span>
    )
}

type ERTextViewProps = {
    span: ERSpan
    setTermId: (id: string) => void
    getProjectTerm: (id: string) => ProjectTerm | undefined
}

const ERTextView = ({ span, setTermId, getProjectTerm }: ERTextViewProps) => {
    const { display } = usePublishedBibleSettings()

    let text = span.text || ''
    if (text.endsWith(' ')) {
        text = text.slice(0, -1)
    }

    const termIds = span.getLexicalLinks()

    return (
        <>
            {termIds.map((termId, index) => {
                let _text = text
                if (index !== 0) {
                    _text = '*'
                }

                // Is it okay to use termId as a key? AFAICT, this component only displays
                // a single link to a single term or several links to different terms, but
                // not several links to the same term.
                if (termId.trim() === '' || !display.termLinks) {
                    return <ERPlainText key={termId} text={_text} />
                }

                return (
                    <ERLink
                        key={termId}
                        text={_text}
                        termId={termId}
                        spanType={span.type}
                        getProjectTerm={getProjectTerm}
                        setTermId={setTermId}
                    />
                )
            })}
        </>
    )
}

type ERKeyTermLinksProps = {
    span: ERSpan
    setTermId: (id: string) => void
    getProjectTerm: (id: string) => ProjectTerm | undefined
}

const ERKeyTermLinks = ({ span, setTermId, getProjectTerm }: ERKeyTermLinksProps) => {
    function doesLinkToKeyTerm(termId: string) {
        return getProjectTerm(termId)?.isKeyTerm ?? false
    }

    const termIds = span.getLexicalLinks()

    return (
        <>
            {termIds.filter(doesLinkToKeyTerm).map((termId) => (
                // Is it okay to use termId as a key? AFAICT, each item in this list links
                // to a different term.
                <StarButton
                    key={termId}
                    buttonClassName="star-button"
                    enabled
                    onClick={() => setTermId(termId)}
                    className="star-icon"
                    tooltip=""
                />
            ))}
        </>
    )
}

const ERSpanView: FC<IERSpanView> = ({ span, setTermId, rt, highlightedVerse, handleClickedVerse }) => {
    const { display } = usePublishedBibleSettings()
    let className = ''

    if (highlightedVerse.length > 0 && highlightedVerse.some((verse) => span.includedIn(verse))) {
        className = 'er-highlight'
    }

    if (span.style) {
        const erStyle = span.style
            .split(' ')
            .map((style) => `er-${style}`)
            .join(' ')

        className = `${erStyle} ${className}`
    }

    if (span.type === 'verse') {
        return (
            <span
                onClick={() => handleClickedVerse(span.toRefRange())}
                data-marbleid={span.id || ''}
                className={`${className} er-v`}
            >
                {span.verse}
            </span>
        )
    }

    if (span.type === 'spacer') {
        return (
            <span
                onClick={() => handleClickedVerse(span.toRefRange())}
                data-marbleid={span.id || ''}
                className={`${className} er-spacer`}
            >
                ...
            </span>
        )
    }

    const isImage = span.attributes && span.attributes.image_links

    let postText = ''
    let text = span.text || ''
    if (text.endsWith(' ')) {
        text = text.slice(0, -1)
        postText = ' '
    }

    return (
        <span onClick={() => handleClickedVerse(span.toRefRange())} data-marbleid={span.id || ''} className={className}>
            {isImage && display.imageLinks && <ERImageView span={span} />}
            <ERTextView span={span} setTermId={setTermId} getProjectTerm={rt.project.getProjectTerm.bind(rt.project)} />
            {display.termLinks && (
                <ERKeyTermLinks
                    span={span}
                    setTermId={setTermId}
                    getProjectTerm={rt.project.getProjectTerm.bind(rt.project)}
                />
            )}
            {postText}
        </span>
    )
}
class SpanGroup {
    color = 0

    spans: ERSpan[] = []
}

interface IERSpanGroup {
    rt: Root
    spanGroup: SpanGroup
    setTermId: (id: string) => void
    highlightedVerse: RefRange[]
    handleClickedVerse: (refs: RefRange[]) => void
}

const ERSpanGroup: FC<IERSpanGroup> = ({ spanGroup, setTermId, rt, highlightedVerse, handleClickedVerse }) => {
    return (
        <span className={`er-highlight-${spanGroup.color}`}>
            {spanGroup.spans.map((span, key) => (
                <ERSpanView
                    key={key}
                    {...{
                        rt,
                        span,
                        setTermId,
                        highlightedVerse,
                        handleClickedVerse
                    }}
                />
            ))}
        </span>
    )
}
interface IERDivView {
    rt: Root
    erDiv: ERDiv
    highlights: PassageHighlight[]
    highlightedVerse: RefRange[]
    setTermId: (id: string) => void
    resource: string
    previousErDiv: ERDiv | null
    handleClickedVerse: (refs: RefRange[]) => void
}

const ERDivView: FC<IERDivView> = ({
    erDiv,
    highlights,
    setTermId,
    resource,
    rt,
    previousErDiv,
    highlightedVerse,
    handleClickedVerse
}) => {
    const { display } = usePublishedBibleSettings()

    // Divide spans up into groups that all have the same value for highlighting
    const spanGroups: SpanGroup[] = []

    erDiv.spans.forEach((span) => {
        const color = PassageHighlight.highlighted(highlights, span.id || '', resource)
        const lastGroup = spanGroups.slice(-1)[0]

        if (!lastGroup || lastGroup.color !== color) {
            const newGroup = new SpanGroup()
            newGroup.color = color
            newGroup.spans.push(span)
            spanGroups.push(newGroup)
        } else {
            lastGroup.spans.push(span)
        }
    })

    let heading = ''
    if (previousErDiv && erDiv.bbbccc !== previousErDiv.bbbccc) {
        const BBB = refToBookId(erDiv.bbbccc)
        const CCC = refToChapterId(erDiv.bbbccc)
        const rr = new RefRange(BBB, BBB)
        heading = `${rt.displayableReferences([rr])} ${parseInt(CCC)}`
    }

    if (erDiv.isHeader() && !display.headers) {
        return null
    }

    return (
        <div data-marbleid={erDiv.id} className={`er-${erDiv.style}`}>
            {heading && <div className="er-new-chapter">{heading}</div>}
            {spanGroups.map((spanGroup, key) => (
                <ERSpanGroup
                    key={key}
                    rt={rt}
                    spanGroup={spanGroup}
                    setTermId={setTermId}
                    highlightedVerse={highlightedVerse}
                    handleClickedVerse={handleClickedVerse}
                />
            ))}
        </div>
    )
}

interface ICopyright {
    currentBible: PublishedBible
    hasAudio: boolean
}
const Copyright = ({ currentBible, hasAudio }: ICopyright) => {
    const { t } = useTranslation()
    const { copyright, audioCopyright } = currentBible

    if (!copyright) return null

    const showAudioCopyright = hasAudio && audioCopyright

    const copyrightMessage = showAudioCopyright ? `${t('copyrightPrefixText')}\n${copyright}` : copyright
    const audioCopyrightMessage = `${t('copyrightPrefixAudio')}\n${audioCopyright}`

    return (
        <div className="er-bible-copyright">
            <div dangerouslySetInnerHTML={{ __html: newlinesToHtmlBreaks(copyrightMessage) }} />
            {showAudioCopyright && (
                <div dangerouslySetInnerHTML={{ __html: newlinesToHtmlBreaks(audioCopyrightMessage) }} />
            )}
        </div>
    )
}

interface IERDivs {
    rt: Root
    currentBible: PublishedBible
    erDivs: ERDiv[]
    highlightedVerse: RefRange[]
    setTermId: (resource: string) => void
    idSuffix: string
    hasAudio: boolean
    handleClickedVerse: (refs: RefRange[]) => void
}

const getHighlightedVerseIds = (highlightedVerse: RefRange[], erDivs: ERDiv[]) => {
    // don't include spans without an id, because we can't find them easily in the DOM
    return erDivs
        .flatMap((div) => div.spans)
        .filter((span) => span.id && highlightedVerse.some((verse) => span.includedIn(verse)))
        .map((span) => span.id ?? '')
}

const getMarbleElement = ({ erViewerBodyId, marbleId }: { erViewerBodyId: string; marbleId: string }) => {
    return document.querySelector(`[id="${erViewerBodyId}"] [data-marbleid="${marbleId}"]`)
}

const ERDivs: FC<IERDivs> = ({
    rt,
    erDivs,
    currentBible,
    setTermId,
    highlightedVerse,
    idSuffix,
    hasAudio,
    handleClickedVerse
}) => {
    const erViewerBodyId = getERViewerBodyId(idSuffix)
    const erDivsId = getERDivsId(idSuffix)

    // Scroll highlightedVerse into view
    useEffect(() => {
        if (!highlightedVerse) {
            return
        }

        const ids = getHighlightedVerseIds(highlightedVerse, erDivs)
        if (!ids.length) {
            return
        }

        // Keep the verse centered within the scroll window. If the verse is taller
        // than the scroll window, scroll to the top of the verse.
        const firstRect = getMarbleElement({ erViewerBodyId, marbleId: ids[0] })?.getBoundingClientRect()
        const lastRect = getMarbleElement({
            erViewerBodyId,
            marbleId: ids[ids.length - 1]
        })?.getBoundingClientRect()
        const scrollContainer = document.getElementById(erViewerBodyId)
        const scrollContentsRect = document.getElementById(erDivsId)?.getBoundingClientRect()
        if (firstRect && lastRect && scrollContainer && scrollContentsRect) {
            const scrollContainerHeight = scrollContainer.getBoundingClientRect().height
            const verseHeight = lastRect.bottom - firstRect.top

            const positionWithinScrollContainer = Math.max((scrollContainerHeight - verseHeight) / 2, 0)
            scrollContainer.scrollTo({
                top: firstRect.y - scrollContentsRect.y - positionWithinScrollContainer,
                left: 0
            })
        }
    }, [highlightedVerse, erDivs, erViewerBodyId, erDivsId])

    const highlights = rt.passageVideo?.highlights ?? []

    const resource = currentBible.id

    let className = ''
    if (resource === 'BHS') {
        className = 'bhs-text'
    } else if (resource === 'UBSGNT5') {
        className = 'ubsgnt5-text'
    }

    return (
        <>
            <div id={erDivsId} className={`er er-divs ${className}`} dir={currentBible.language.direction}>
                {erDivs.map((erDiv, key) => (
                    <ERDivView
                        key={key}
                        {...{
                            rt,
                            erDiv,
                            setTermId,
                            highlights,
                            resource,
                            previousErDiv: key > 0 ? erDivs[key - 1] : null,
                            highlightedVerse,
                            handleClickedVerse
                        }}
                    />
                ))}
            </div>
            {erDivs.length > 0 && <Copyright currentBible={currentBible} hasAudio={hasAudio} />}
        </>
    )
}

interface IERBody {
    rt: Root
    currentBible: PublishedBible
    highlightedVerse: RefRange[]
    refs: RefRange[]
    idSuffix: string
    hasAudio: boolean
    handleClickedVerse: (refs: RefRange[]) => void
    hasInputReference: boolean
}

const ERBody: FC<IERBody> = ({
    rt,
    currentBible,
    refs,
    highlightedVerse,
    idSuffix,
    hasAudio,
    handleClickedVerse,
    hasInputReference
}) => {
    const { t } = useTranslation()
    const [termId, setTermId] = useState('')
    const [erDivs, setErDivs] = useState<ERDiv[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const latestResourceRef = useRef<string>('')

    const resource = currentBible.id

    useEffect(() => {
        const fetchERDivs = async () => {
            if (!refs.length) {
                setErDivs([])
                return
            }

            const refsString = refs.map((r) => r.startRef + r.endRef).toString()
            const resourceRef = resource + refsString
            latestResourceRef.current = resourceRef
            try {
                setIsLoading(true)
                const _erDivs = await EnhancedResources.fetchRefs(resource, refs)

                // Make sure the last call to this function is the one that takes precendence
                // so we avoid a race condition.
                if (resourceRef === latestResourceRef.current) {
                    setErDivs(_erDivs)
                }
                setIsLoading(false)
            } catch (error) {
                log('!!!', error)
                if (resourceRef === latestResourceRef.current) {
                    setErDivs([])
                }
                setIsLoading(false)
            }
        }

        fetchERDivs()
    }, [resource, refs])

    if (isLoading) {
        return <div>{t('loading')}</div>
    }

    if (!hasInputReference && !erDivs.length) {
        return <div>{t('enterBibleReference')}</div>
    }

    if (hasInputReference && !erDivs.length) {
        return <div>{t('noBibleRefForTranslation ')}</div>
    }

    return (
        <div>
            {termId && <ERTermModal {...{ rt, termId, setTermId }} />}
            <ERDivs
                {...{
                    rt,
                    erDivs,
                    currentBible,
                    setTermId,
                    highlightedVerse,
                    idSuffix,
                    hasAudio,
                    handleClickedVerse
                }}
            />
        </div>
    )
}

// A list of spans with the same background color

// Modal dialog to display an image associated with a word.

// A span representing a word or very short phrase in the enhanced resource.
// If it has lexical_links you can click on it to see info for that term.
// If it has image_links we display an image icon to click to see the image.

interface IEnhancedResourceViewer {
    rt: Root
    persistenceTag: string
    idSuffix: string
    passageReferences: RefRange[]
    isPlaying: string
    setIsPlaying: (isPlaying: string) => void
    selectedVerse: RefRange[]
    setSelectedVerse: (refs: RefRange[]) => void
}

export const EnhancedResourceViewer: FC<IEnhancedResourceViewer> = observer(
    ({ rt, persistenceTag, idSuffix, passageReferences, isPlaying, setIsPlaying, selectedVerse, setSelectedVerse }) => {
        const { t } = useTranslation()
        const { defaultBibleId, visibleBibles } = usePublishedBibles()
        const audioPlayerRef = useRef<HTMLAudioElement | null>(null)

        const initialResource = rt.getDefault(persistenceTag) || defaultBibleId
        const [currentBible, setCurrentBible] = useState(visibleBibles.find((bible) => bible.id === initialResource))

        const [settingsOpen, setSettingsOpen] = useState(false)

        const [bibleRefs, setBibleRefs] = useState<RefRange[]>([])
        const [errored, setErrored] = useState(false)
        const [chapterTimeCodes, setChapterTimeCodes] = useState<ChapterTimeCodes>()

        const setBible = (bible: PublishedBible) => {
            rt.setDefault(persistenceTag, bible.id)
            setCurrentBible(bible)
            setSelectedVerse([])
        }

        const setRefs = useCallback(
            (newRefs: RefRange[]) => {
                setBibleRefs(newRefs)
                setSelectedVerse([])
            },
            [setSelectedVerse]
        )

        const handleClickedVerse = useCallback(
            (refs: RefRange[]) => {
                setSelectedVerse(refs)

                if (!refs.length) {
                    return
                }

                const refStartVerse = refs[0].startRef

                if (
                    refToChapterId(refStartVerse) !== refToChapterId(chapterTimeCodes?.chapter || '') ||
                    refToBookId(refStartVerse) !== refToBookId(chapterTimeCodes?.chapter || '')
                ) {
                    return
                }

                const verseId = refStartVerse.length && parseInt(refToVerseId(refStartVerse), 10).toString()
                const findVerseTimeCode = () => {
                    if (!chapterTimeCodes?.codes) {
                        return undefined
                    }

                    for (const code of chapterTimeCodes.codes) {
                        const verseIdSplit = code.verseId.split('-')
                        const startVerse = parseInt(verseIdSplit[0], 10)
                        const endVerse = parseInt(verseIdSplit[1], 10)

                        if (
                            (verseIdSplit.length > 1 && inRangeInclusive(Number(verseId), startVerse, endVerse)) ||
                            verseIdSplit[0] === verseId
                        ) {
                            return code
                        }
                    }
                    return undefined
                }
                const verseTimeCode = verseId ? findVerseTimeCode() : undefined

                if (verseTimeCode && audioPlayerRef.current) {
                    audioPlayerRef.current.currentTime = Number(verseTimeCode.start)
                }
            },
            [chapterTimeCodes, setSelectedVerse]
        )

        useEffect(() => {
            const selectedPassageReference = rt.displayableReferences(rt.passageReferences)
            const currentVerseReference = rt.displayableReferences(rt.dbsRefs)

            if (selectedPassageReference === currentVerseReference || rt.playing) {
                handleClickedVerse([])
                return
            }

            const doesVerseExist = currentVerseReference.split(':')[1]
            const parsedCurrentVerseReference = rt.parseReferences(currentVerseReference, false)
            handleClickedVerse(doesVerseExist ? parsedCurrentVerseReference : [])
        }, [rt, rt.dbsRefs, rt.passageReferences, rt.playing, handleClickedVerse])

        const stickyReferenceId = `${persistenceTag}Reference`

        const refInput = rt

        const currentBibleRefHasAudio =
            currentBible && bibleRefs.length > 0 && audioBookExists(bibleRefs[0].getStartBookNumber(), currentBible)

        const isInPrimaryPane = persistenceTag.includes(RightPaneId.Primary)

        return (
            <div className="er-viewer">
                <div className="er-viewer-toolbar">
                    <ReferenceSearchInput
                        {...{
                            refInput,
                            setRefs,
                            stickyReferenceId,
                            errored,
                            setErrored,
                            passageReferences
                        }}
                    />
                    <ERResourceSelector
                        setBible={setBible}
                        visibleBibles={visibleBibles}
                        currentBible={currentBible}
                        idSuffix={idSuffix}
                        pullRight
                    />
                    {isInPrimaryPane && (
                        <SettingsButton
                            onClick={() => setSettingsOpen(true)}
                            className="right-pane-button segment-document-button"
                            tooltip={t('Settings')}
                            enabled
                        />
                    )}
                </div>
                {currentBible && (
                    <>
                        {currentBibleRefHasAudio && (
                            <>
                                <BibleAudioPlayer
                                    bible={currentBible}
                                    reference={bibleRefs[0]}
                                    project={rt.project}
                                    onVerseUpdate={setSelectedVerse}
                                    audioPlayerRef={audioPlayerRef}
                                    chapterTimeCodes={chapterTimeCodes}
                                    setChapterTimeCodes={setChapterTimeCodes}
                                    isPlaying={isPlaying}
                                    setIsPlaying={setIsPlaying}
                                    idSuffix={idSuffix}
                                />
                                <div className="published-audio-separator" />
                            </>
                        )}
                        <div className="er-viewer-body" id={getERViewerBodyId(idSuffix)}>
                            <ERBody
                                rt={rt}
                                currentBible={currentBible}
                                refs={bibleRefs}
                                highlightedVerse={selectedVerse}
                                idSuffix={idSuffix}
                                hasAudio={currentBibleRefHasAudio ?? false}
                                handleClickedVerse={handleClickedVerse}
                                hasInputReference={refInput.displayableReferences(bibleRefs).length > 0}
                            />
                        </div>
                    </>
                )}
                {settingsOpen && <PublishedBibleSettings closeModal={() => setSettingsOpen(false)} />}
            </div>
        )
    }
)
