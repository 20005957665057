import { useTheme, View, Text } from '@aws-amplify/ui-react'
import { useTranslation } from 'react-i18next'

import { HelpLink } from '../../utils/Icons'

export const Footer = () => {
    const { tokens } = useTheme()
    const { t } = useTranslation()

    return (
        <View textAlign="center" padding={tokens.space.large}>
            <Text className="amplify-field__description">{t('signUpDisclaimer')}</Text>
            <Text paddingTop="1rem" color={`${tokens.colors.neutral['80']}`} translate="no">
                <HelpLink className="fa-2x" tooltip={t('Get help')} />
            </Text>
        </View>
    )
}
