/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/no-cycle */

import { useState, useEffect } from 'react'

import { Authenticator } from '@aws-amplify/ui-react'
import { Amplify, Auth, I18n } from 'aws-amplify'
import { useTranslation } from 'react-i18next'

import { Footer } from './Footer'
import { Header } from './Header'
import { i18nVocabularies } from './i18nVocabularies'
import { SignIn } from './SignIn'
import { SignUp, validateCustomSignUp } from './SignUp'
import { getLocalStorageAuthType, getLocalStorageUsername, getLocalStorageIdToken } from '../../../models3/AppRoot'
import { canAccessInternet } from '../OnlineStatusContext'
import SLTool from '../SLTool'
import { isGoAVTT } from '../slttAvtt'

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'
import './SLToolWithCognito.css'

const {
    REACT_APP_AWS_REGION = '',
    REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID = '',
    REACT_APP_AWS_USER_POOLS_ID = '',
    REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID = ''
} = process.env

Amplify.configure({
    aws_cognito_identity_pool_id: REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: REACT_APP_AWS_REGION,
    aws_project_region: REACT_APP_AWS_REGION,
    aws_user_pools_id: REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
})

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:SLToolWithCognito')

const SLToolWithCognito = () => {
    const { t } = useTranslation()
    const [skipCognito, setSkipCognito] = useState(false)
    const [isGettingCurrentUser, setIsGettingCurrentUser] = useState(false)
    // const [user, setUser] = useState<any>()

    I18n.putVocabulariesForLanguage('en', i18nVocabularies(t))

    useEffect(() => {
        const getCurrentUser = async (): Promise<void> => {
            setIsGettingCurrentUser(true)

            try {
                await Auth.currentAuthenticatedUser()
                // const authUser = await Auth.currentAuthenticatedUser()
                // setUser(authUser)
                // setCurrentUser(await Auth.currentAuthenticatedUser())
            } catch (error) {
                log('Error while getting currentAuthenticatedUser', error)

                if (error === 'The user is not authenticated') {
                    const authType = getLocalStorageAuthType()
                    const username = getLocalStorageUsername()
                    const idToken = getLocalStorageIdToken()

                    // use canAccessInternet instead of useOnlineStatus to prevent reloading
                    if (
                        username &&
                        idToken &&
                        authType === 'cognito' &&
                        !(await canAccessInternet('Cognito getCurrentUser'))
                    ) {
                        log('Previously validated user is offline so, keep going')
                        setSkipCognito(true)
                    }
                }
            }

            setIsGettingCurrentUser(false)
        }

        getCurrentUser()
    }, [])

    if (isGettingCurrentUser) {
        // We should be able to return a string, but typescript doesn't think so. Wrap in a
        // fragment as a workaround. See
        // https://stackoverflow.com/questions/65708107/how-to-return-string-or-jsx-element-in-typescript-react-component
        return <>Authenticating user...</>
    }

    if (skipCognito) {
        return <SLTool authType="cognito" />
    }

    const components = { Header, Footer, SignIn, SignUp: isGoAVTT ? SignUp : undefined }
    const services = isGoAVTT ? { validateCustomSignUp } : undefined

    return (
        <Authenticator
            loginMechanisms={['email']}
            components={components}
            services={services}
            hideSignUp={!isGoAVTT}
            initialState={isGoAVTT ? 'signUp' : 'signIn'}
        >
            {({ user }) => {
                // This component unmounts and remounts when the outer component rerenders
                const username = user?.attributes?.email
                const idToken = user?.getSignInUserSession()?.getIdToken().getJwtToken()

                return <SLTool username={username} idToken={idToken} authType="cognito" />
            }}
        </Authenticator>
    )
}

export default SLToolWithCognito
