import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { MediaSlice } from '../../models3/MediaSlice'
import { MIN_SEGMENT_LENGTH } from '../../models3/PassageSegment'
import { Project } from '../../models3/Project'
import { Root } from '../../models3/Root'
import { CutButton } from '../utils/Buttons'
import { systemError } from '../utils/Errors'
import { AudioSection, decrementTime, incrementTime } from '../utils/Helpers'
import { encodeOpus } from '../utils/Opus'
import VideoCompressor from '../utils/VideoCompressor'
import { deleteAudioSection } from '../utils/Wav'
import { AudioContextFactory } from '../video/WaveformVisualizer'

interface Props {
    isInReview: boolean
    isNotPlayingNorRecording: boolean
    isSelectionPresent: boolean
    project: Project
    rt: Root
}

const SegmentCutButton: FC<Props> = ({ isInReview, isNotPlayingNorRecording, isSelectionPresent, project, rt }) => {
    const { t } = useTranslation()
    const { iAmTranslatorForPassage, name, passage, passageSegment, passageVideo, timeline } = rt

    const allowRecordingSectionDeletion = () => {
        if (!isSelectionPresent) {
            return false
        }

        if (
            !(
                iAmTranslatorForPassage(passage) &&
                passageVideo &&
                passage &&
                passageSegment &&
                passageVideo.getVisibleBaseSegments().length > 1
            )
        ) {
            return false
        }

        if (isInReview) {
            return false
        }

        const { selectionStartTime, selectionEndTime } = rt.timeline.getSelectionTimes()
        const startIndex = passageVideo.timeToSegmentIndex(selectionStartTime)
        const endIndex = passageVideo.timeToSegmentIndex(selectionEndTime)
        if (startIndex === -1 || endIndex === -1 || startIndex !== endIndex) {
            return false
        }

        const as = passageSegment.actualSegment(passage)
        if (as.endPosition - as.position - (selectionEndTime - selectionStartTime) < MIN_SEGMENT_LENGTH) {
            return false
        }

        return true
    }

    const getSlicedAudio = async (slice: MediaSlice, sectionsToKeepSeconds: AudioSection[]) => {
        const audioContext = AudioContextFactory.getAudioContext()
        const wavBlob = await deleteAudioSection(audioContext, slice.src, sectionsToKeepSeconds)
        return encodeOpus(wavBlob)
    }

    const getSlicedVideo = async (sectionsToKeepSeconds: AudioSection[]) => {
        if (!(await VideoCompressor.checkIfServerRunning()) || !passage) {
            return
        }
        const { currentVideos } = rt
        const setProgressMessage = passage.setCompressionProgressMessage.bind(passage)
        const { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = project
        const compressor = new VideoCompressor(
            {
                crf: compressedVideoQuality,
                resolution: compressedVideoResolution,
                maxFileSizeMB: maxVideoSizeMB,
                rescaleVideo: false
            },
            setProgressMessage
        )
        return compressor.deleteSection(currentVideos, passage, sectionsToKeepSeconds)
    }

    const deleteRecordingSection = async () => {
        try {
            if (
                !isSelectionPresent ||
                !passage ||
                !passageVideo ||
                !passageSegment ||
                !passageSegment.actualVideo(passage)
            ) {
                return
            }

            const as = passageSegment.actualSegment(passage)
            const slices = await as.getPlayableSlicesForViewablePartOfSegment(passage)
            if (!slices.length) {
                return
            }

            const { selectionStartTime, selectionEndTime } = timeline.getSelectionTimes()
            const slice = slices[0]

            let slicedFile
            if (passageVideo.isAudioOnly()) {
                const sectionsToKeep: AudioSection[] = [
                    { start: slice.startPosition, end: as.timeToPosition(selectionStartTime) },
                    { start: as.timeToPosition(selectionEndTime), end: slice.endPosition }
                ]
                slicedFile = await getSlicedAudio(slice, sectionsToKeep)
            } else {
                // make this a little smaller so we know it is in the current segment and not the next one
                const sectionsToKeep: AudioSection[] = [
                    {
                        start: as.positionToTime(slice.startPosition),
                        end: decrementTime(selectionStartTime)
                    },
                    {
                        start: selectionEndTime,
                        end: decrementTime(as.positionToTime(slice.endPosition))
                    }
                ]
                slicedFile = await getSlicedVideo(sectionsToKeep)
            }
            if (!slicedFile) {
                return
            }
            const patch = await passage.uploadFile(slicedFile, name)
            await passage.addPatchVideo({
                baseRecording: passageVideo,
                patch,
                baseSegment: passageSegment,
                onTopSegment: passageSegment.actualSegment(passage)
            })
            rt.setPassageVideo(passageVideo)
            rt.setPassageSegment(passageSegment)
            rt.resetCurrentTime(incrementTime(passageSegment.actualSegment(passage).time))
        } catch (error) {
            systemError(error)
        }
    }

    return (
        <CutButton
            onClick={deleteRecordingSection}
            className="sl-segment-toolbar-button scissors-icon"
            tooltip={t('Delete section')}
            enabled={isNotPlayingNorRecording && allowRecordingSectionDeletion()}
        />
    )
}

export default SegmentCutButton
